import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInput } from '../../hooks/input-hook';


function Phone(props) {
    const { t } = useTranslation();
    const { bind:bindHomePhone, setValue:setHomePhone } = useInput('', (ev) =>  {
        const homePhone = ev.target.value;
        setHomePhone(homePhone);
        props.setPhones({homePhone});
    });
    return (
        <div>
            <div className="" id="headingPhone">
                <div className="pl-0 d-flex align-items-center collapsed" data-toggle="collapse" data-target="#collapsePhone" aria-expanded="true" aria-controls="collapsePhone">
                    <span className="h1 font-weight-light mb-1 mr-2 collapsed-plus">+</span>
                    <span className="h6 font-weight-normal m-0">{t('home.phone2')}</span>
                </div>
            </div>
            <div id="collapsePhone" className="collapse" aria-labelledby="headingPhone" data-parent="#additionalFields">
                <div className="form-group">
                    <label htmlFor="homePhone">{t('home.homePhone')}</label>
                    <input type="tel" className="form-control form-control form-control-lg font-weight-light" id="homePhone" placeholder="+41435232732" pattern="[\+0-9 ]*" {...bindHomePhone} />
                </div>
            </div>
        </div>
    )
}


function Additional(props) {
  return (
    <div className="accordion" id="additionalFields">
        <Phone {...props}></Phone>
    </div>  
  );
}

export default Additional;
